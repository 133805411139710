/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "AsapMedium";
  src: url("/extras/fonts/Asap-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "AsapSemiBold";
  src: url("/extras/fonts/Asap-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "AsapBold";
  src: url("/extras/fonts/Asap-Bold.ttf") format("truetype");
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 13px;
  margin-bottom: 13px;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 13px;
  margin-bottom: 13px;
}
.lead {
  margin-bottom: 0 !important;
}
#edit .line {
  padding: 4px 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #2D3C5A;
  color: #2D3C5A;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #2D3C5A !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
  height: auto;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 3001;
  color: #2D3C5A;
  font-family: 'AsapMedium', helvetica, sans-serif;
  line-height: 1.625;
  font-size: 16px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #D02C7D;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #D02C7D;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
.table td {
  padding: 5px 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
@media (max-width: 767px) {
  .table {
    font-size: 2.08604954vw;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(208, 44, 125, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1278px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  float: left;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #2D3C5A;
  font-family: 'AsapMedium', helvetica, sans-serif;
  line-height: 1.625;
  font-size: 16px;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.ie10 .wrapper,
.ie11 .wrapper {
  display: block;
}
.section,
.container {
  float: left;
  width: 100%;
  position: relative;
}
.section--main {
  flex: 1 0 auto;
}
.container--base {
  background-color: #F8F8F8;
}
.left,
.right {
  float: left;
  width: 100%;
}
.navbar {
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 12px;
  background-color: #fff;
}
.navbar:before {
  content: "";
  position: absolute;
  z-index: 213;
  left: 0;
  top: 0;
  width: 100%;
  height: 12px;
  background: #e62e78;
  background: linear-gradient(90deg, #e62e78 0%, #982886 100%);
}
.nav {
  float: right;
  display: none;
}
.navi-services {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  float: left;
  width: 100%;
  padding-right: 40px;
}
.navi-services .meta {
  float: left;
  display: block;
  min-width: 34px;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  background-position: center center;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2.16666667;
  font-family: 'AsapSemiBold', helvetica, sans-serif;
}
.navi.languageNavi {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  float: right;
  top: 43px;
  margin-left: 12px;
  padding-left: 34px;
  background: url(/images/icon-globe.svg) no-repeat left top;
  background-size: 34px 34px;
  transition: all 250ms;
}
.navi.languageNavi > .item {
  display: block;
  width: 100%;
  border-right: 1px solid #707070;
  transition: all 250ms;
}
.navi.languageNavi > .item.exit {
  border-right: none;
}
.navi.languageNavi > .item > .menu {
  display: block;
  padding: 4px 8px;
  color: #D02C7D;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2.16666667;
  font-family: 'AsapSemiBold', helvetica, sans-serif;
  text-transform: uppercase;
  text-align: center;
}
.navi.languageNavi > .item > .menu.path {
  color: #707070;
}
.section--header {
  background-color: #2D3C5A;
  color: #fff;
}
.section--header a {
  color: #fff;
}
.section--header a:hover,
.section--header a:focus {
  color: #fff;
}
.header-content {
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  float: left;
  overflow: hidden;
}
.breadcrumb {
  display: none;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.14285714;
}
.breadcrumb a {
  color: #fff;
}
.breadcrumb a:hover,
.breadcrumb a:focus {
  color: #fff;
}
.breadcrumb .here {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
#expo {
  position: absolute;
  z-index: 1;
  right: 3.75586854%;
  bottom: 0;
  width: 42.33176839%;
  height: 0;
  padding-bottom: 37.55868545%;
}
.cb-layout2 #expo,
.cb-layout3 #expo {
  right: 0;
  width: 60.87636933%;
  padding-bottom: 34.27230047%;
}
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#expo div.link {
  display: none;
}
@media only screen and (max-width: 767px) {
  #expo {
    right: -12.6984127%;
    width: 264px;
    padding-bottom: 235px;
  }
  .cb-layout2 #expo,
  .cb-layout3 #expo {
    right: -70px;
    width: 375px;
    padding-bottom: 211px;
  }
}
@media only screen and (max-width: 1279px) {
  #expo {
    right: 0;
  }
  .cb-layout2 #expo {
    right: -70px;
  }
}
.section--footer {
  position: relative;
  background-color: #2D3C5A;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
.section--footer:before {
  content: "";
  position: absolute;
  z-index: 213;
  left: 0;
  top: 0;
  width: 100%;
  height: 12px;
  background: #e62e78;
  background: linear-gradient(90deg, #e62e78 0%, #982886 100%);
}
.section--footer a {
  color: #fff;
}
.section--footer a:hover,
.section--footer a:focus {
  color: rgba(255, 255, 255, 0.6);
}
.section--footer .addresses,
.section--footer .links,
.section--footer .footer-services {
  float: left;
  width: 100%;
}
.section--footer .logo-container {
  width: 163px;
  height: 55px;
}
.section--footer .vcard {
  margin-top: 40px;
}
.section--footer .vcard .org {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
.section--footer #footerLinks {
  float: left;
  width: 100%;
  margin-top: 23px;
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
.section--footer #footerLinks .meta {
  display: block;
  float: left;
  width: 100%;
  margin: 12px 0;
}
.section--footer .footer-services {
  margin-bottom: 30px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.66666667;
  color: rgba(255, 255, 255, 0.5);
}
.section--footer .footer-services .meta {
  color: rgba(255, 255, 255, 0.5);
}
.section--footer .footer-services .meta:hover,
.section--footer .footer-services .meta:focus {
  color: #fff;
}
.section--footer #cmsbox {
  text-transform: lowercase;
}
.fr #footerLinks .service_footerLinks-informationsabende {
  display: none;
}
.bubble {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 318px;
  height: 318px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: -50px;
  margin-bottom: 26px;
  padding: 30px 30px 45px 30px;
  background: url(/images/bg-bubble.png) no-repeat center;
  color: #fff;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.19047619;
  text-align: center;
}
.c2 .section--header {
  background-color: #A7D5C2;
  color: #fff;
}
.c2 .main h2,
.c2 .side h2,
.c2 .main h3,
.c2 .side h3 {
  color: #A7D5C2;
}
.c2 .unit.seam {
  background-color: rgba(167, 213, 194, 0.2);
}
.c2 .unit.flat {
  border: 1px solid rgba(167, 213, 194, 0.2);
}
.c2 .farnorth div.link .open,
.c2 .farnorth div.load .open,
.c2 .farnorth div.link .load,
.c2 .farnorth div.load .load {
  background: #fff;
  color: #A7D5C2;
  transition: opacity 250ms;
}
.c2 .farnorth div.link .open:before,
.c2 .farnorth div.load .open:before,
.c2 .farnorth div.link .load:before,
.c2 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23A7D5C2' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c2 .farnorth div.link .open:hover,
.c2 .farnorth div.load .open:hover,
.c2 .farnorth div.link .load:hover,
.c2 .farnorth div.load .load:hover,
.c2 .farnorth div.link .open:focus,
.c2 .farnorth div.load .open:focus,
.c2 .farnorth div.link .load:focus,
.c2 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c2 .main .pure div.link .open,
.c2 .main .pure div.load .open,
.c2 .main .pure div.link .load,
.c2 .main .pure div.load .load {
  background: #A7D5C2;
  color: #fff;
  transition: opacity 250ms;
}
.c2 .main .pure div.link .open:before,
.c2 .main .pure div.load .open:before,
.c2 .main .pure div.link .load:before,
.c2 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c2 .main .pure div.link .open:hover,
.c2 .main .pure div.load .open:hover,
.c2 .main .pure div.link .load:hover,
.c2 .main .pure div.load .load:hover,
.c2 .main .pure div.link .open:focus,
.c2 .main .pure div.load .open:focus,
.c2 .main .pure div.link .load:focus,
.c2 .main .pure div.load .load:focus {
  background: #A7D5C2;
  opacity: 0.8;
}
.c2 .side div.link .open,
.c2 .side div.load .open,
.c2 .side div.link .load,
.c2 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23A7D5C2' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c3 .section--header {
  background-color: #A28A9D;
  color: #fff;
}
.c3 .main h2,
.c3 .side h2,
.c3 .main h3,
.c3 .side h3 {
  color: #A28A9D;
}
.c3 .unit.seam {
  background-color: rgba(162, 138, 157, 0.2);
}
.c3 .unit.flat {
  border: 1px solid rgba(162, 138, 157, 0.2);
}
.c3 .farnorth div.link .open,
.c3 .farnorth div.load .open,
.c3 .farnorth div.link .load,
.c3 .farnorth div.load .load {
  background: #fff;
  color: #A28A9D;
  transition: opacity 250ms;
}
.c3 .farnorth div.link .open:before,
.c3 .farnorth div.load .open:before,
.c3 .farnorth div.link .load:before,
.c3 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23A28A9D' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c3 .farnorth div.link .open:hover,
.c3 .farnorth div.load .open:hover,
.c3 .farnorth div.link .load:hover,
.c3 .farnorth div.load .load:hover,
.c3 .farnorth div.link .open:focus,
.c3 .farnorth div.load .open:focus,
.c3 .farnorth div.link .load:focus,
.c3 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c3 .main .pure div.link .open,
.c3 .main .pure div.load .open,
.c3 .main .pure div.link .load,
.c3 .main .pure div.load .load {
  background: #A28A9D;
  color: #fff;
  transition: opacity 250ms;
}
.c3 .main .pure div.link .open:before,
.c3 .main .pure div.load .open:before,
.c3 .main .pure div.link .load:before,
.c3 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c3 .main .pure div.link .open:hover,
.c3 .main .pure div.load .open:hover,
.c3 .main .pure div.link .load:hover,
.c3 .main .pure div.load .load:hover,
.c3 .main .pure div.link .open:focus,
.c3 .main .pure div.load .open:focus,
.c3 .main .pure div.link .load:focus,
.c3 .main .pure div.load .load:focus {
  background: #A28A9D;
  opacity: 0.8;
}
.c3 .side div.link .open,
.c3 .side div.load .open,
.c3 .side div.link .load,
.c3 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23A28A9D' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c4 .section--header {
  background-color: #54708C;
  color: #fff;
}
.c4 .main h2,
.c4 .side h2,
.c4 .main h3,
.c4 .side h3 {
  color: #54708C;
}
.c4 .unit.seam {
  background-color: rgba(84, 112, 140, 0.2);
}
.c4 .unit.flat {
  border: 1px solid rgba(84, 112, 140, 0.2);
}
.c4 .farnorth div.link .open,
.c4 .farnorth div.load .open,
.c4 .farnorth div.link .load,
.c4 .farnorth div.load .load {
  background: #fff;
  color: #54708C;
  transition: opacity 250ms;
}
.c4 .farnorth div.link .open:before,
.c4 .farnorth div.load .open:before,
.c4 .farnorth div.link .load:before,
.c4 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%2354708C' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c4 .farnorth div.link .open:hover,
.c4 .farnorth div.load .open:hover,
.c4 .farnorth div.link .load:hover,
.c4 .farnorth div.load .load:hover,
.c4 .farnorth div.link .open:focus,
.c4 .farnorth div.load .open:focus,
.c4 .farnorth div.link .load:focus,
.c4 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c4 .main .pure div.link .open,
.c4 .main .pure div.load .open,
.c4 .main .pure div.link .load,
.c4 .main .pure div.load .load {
  background: #54708C;
  color: #fff;
  transition: opacity 250ms;
}
.c4 .main .pure div.link .open:before,
.c4 .main .pure div.load .open:before,
.c4 .main .pure div.link .load:before,
.c4 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c4 .main .pure div.link .open:hover,
.c4 .main .pure div.load .open:hover,
.c4 .main .pure div.link .load:hover,
.c4 .main .pure div.load .load:hover,
.c4 .main .pure div.link .open:focus,
.c4 .main .pure div.load .open:focus,
.c4 .main .pure div.link .load:focus,
.c4 .main .pure div.load .load:focus {
  background: #54708C;
  opacity: 0.8;
}
.c4 .side div.link .open,
.c4 .side div.load .open,
.c4 .side div.link .load,
.c4 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%2354708C' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c5 .section--header {
  background-color: #F08372;
  color: #fff;
}
.c5 .main h2,
.c5 .side h2,
.c5 .main h3,
.c5 .side h3 {
  color: #F08372;
}
.c5 .unit.seam {
  background-color: rgba(240, 131, 114, 0.2);
}
.c5 .unit.flat {
  border: 1px solid rgba(240, 131, 114, 0.2);
}
.c5 .farnorth div.link .open,
.c5 .farnorth div.load .open,
.c5 .farnorth div.link .load,
.c5 .farnorth div.load .load {
  background: #fff;
  color: #F08372;
  transition: opacity 250ms;
}
.c5 .farnorth div.link .open:before,
.c5 .farnorth div.load .open:before,
.c5 .farnorth div.link .load:before,
.c5 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23F08372' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c5 .farnorth div.link .open:hover,
.c5 .farnorth div.load .open:hover,
.c5 .farnorth div.link .load:hover,
.c5 .farnorth div.load .load:hover,
.c5 .farnorth div.link .open:focus,
.c5 .farnorth div.load .open:focus,
.c5 .farnorth div.link .load:focus,
.c5 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c5 .main .pure div.link .open,
.c5 .main .pure div.load .open,
.c5 .main .pure div.link .load,
.c5 .main .pure div.load .load {
  background: #F08372;
  color: #fff;
  transition: opacity 250ms;
}
.c5 .main .pure div.link .open:before,
.c5 .main .pure div.load .open:before,
.c5 .main .pure div.link .load:before,
.c5 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c5 .main .pure div.link .open:hover,
.c5 .main .pure div.load .open:hover,
.c5 .main .pure div.link .load:hover,
.c5 .main .pure div.load .load:hover,
.c5 .main .pure div.link .open:focus,
.c5 .main .pure div.load .open:focus,
.c5 .main .pure div.link .load:focus,
.c5 .main .pure div.load .load:focus {
  background: #F08372;
  opacity: 0.8;
}
.c5 .side div.link .open,
.c5 .side div.load .open,
.c5 .side div.link .load,
.c5 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23F08372' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c6 .section--header {
  background-color: #ACB4B8;
  color: #fff;
}
.c6 .main h2,
.c6 .side h2,
.c6 .main h3,
.c6 .side h3 {
  color: #ACB4B8;
}
.c6 .unit.seam {
  background-color: rgba(172, 180, 184, 0.2);
}
.c6 .unit.flat {
  border: 1px solid rgba(172, 180, 184, 0.2);
}
.c6 .farnorth div.link .open,
.c6 .farnorth div.load .open,
.c6 .farnorth div.link .load,
.c6 .farnorth div.load .load {
  background: #fff;
  color: #ACB4B8;
  transition: opacity 250ms;
}
.c6 .farnorth div.link .open:before,
.c6 .farnorth div.load .open:before,
.c6 .farnorth div.link .load:before,
.c6 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23ACB4B8' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c6 .farnorth div.link .open:hover,
.c6 .farnorth div.load .open:hover,
.c6 .farnorth div.link .load:hover,
.c6 .farnorth div.load .load:hover,
.c6 .farnorth div.link .open:focus,
.c6 .farnorth div.load .open:focus,
.c6 .farnorth div.link .load:focus,
.c6 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c6 .main .pure div.link .open,
.c6 .main .pure div.load .open,
.c6 .main .pure div.link .load,
.c6 .main .pure div.load .load {
  background: #ACB4B8;
  color: #fff;
  transition: opacity 250ms;
}
.c6 .main .pure div.link .open:before,
.c6 .main .pure div.load .open:before,
.c6 .main .pure div.link .load:before,
.c6 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c6 .main .pure div.link .open:hover,
.c6 .main .pure div.load .open:hover,
.c6 .main .pure div.link .load:hover,
.c6 .main .pure div.load .load:hover,
.c6 .main .pure div.link .open:focus,
.c6 .main .pure div.load .open:focus,
.c6 .main .pure div.link .load:focus,
.c6 .main .pure div.load .load:focus {
  background: #ACB4B8;
  opacity: 0.8;
}
.c6 .side div.link .open,
.c6 .side div.load .open,
.c6 .side div.link .load,
.c6 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23ACB4B8' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c7 .section--header {
  background-color: #D9CCC0;
  color: #fff;
}
.c7 .main h2,
.c7 .side h2,
.c7 .main h3,
.c7 .side h3 {
  color: #D9CCC0;
}
.c7 .unit.seam {
  background-color: rgba(217, 204, 192, 0.2);
}
.c7 .unit.flat {
  border: 1px solid rgba(217, 204, 192, 0.2);
}
.c7 .farnorth div.link .open,
.c7 .farnorth div.load .open,
.c7 .farnorth div.link .load,
.c7 .farnorth div.load .load {
  background: #fff;
  color: #D9CCC0;
  transition: opacity 250ms;
}
.c7 .farnorth div.link .open:before,
.c7 .farnorth div.load .open:before,
.c7 .farnorth div.link .load:before,
.c7 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23D9CCC0' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c7 .farnorth div.link .open:hover,
.c7 .farnorth div.load .open:hover,
.c7 .farnorth div.link .load:hover,
.c7 .farnorth div.load .load:hover,
.c7 .farnorth div.link .open:focus,
.c7 .farnorth div.load .open:focus,
.c7 .farnorth div.link .load:focus,
.c7 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c7 .main .pure div.link .open,
.c7 .main .pure div.load .open,
.c7 .main .pure div.link .load,
.c7 .main .pure div.load .load {
  background: #D9CCC0;
  color: #fff;
  transition: opacity 250ms;
}
.c7 .main .pure div.link .open:before,
.c7 .main .pure div.load .open:before,
.c7 .main .pure div.link .load:before,
.c7 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c7 .main .pure div.link .open:hover,
.c7 .main .pure div.load .open:hover,
.c7 .main .pure div.link .load:hover,
.c7 .main .pure div.load .load:hover,
.c7 .main .pure div.link .open:focus,
.c7 .main .pure div.load .open:focus,
.c7 .main .pure div.link .load:focus,
.c7 .main .pure div.load .load:focus {
  background: #D9CCC0;
  opacity: 0.8;
}
.c7 .side div.link .open,
.c7 .side div.load .open,
.c7 .side div.link .load,
.c7 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23D9CCC0' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c8 .section--header {
  background-color: #F7B49A;
  color: #fff;
}
.c8 .main h2,
.c8 .side h2,
.c8 .main h3,
.c8 .side h3 {
  color: #F7B49A;
}
.c8 .unit.seam {
  background-color: rgba(247, 180, 154, 0.2);
}
.c8 .unit.flat {
  border: 1px solid rgba(247, 180, 154, 0.2);
}
.c8 .farnorth div.link .open,
.c8 .farnorth div.load .open,
.c8 .farnorth div.link .load,
.c8 .farnorth div.load .load {
  background: #fff;
  color: #F7B49A;
  transition: opacity 250ms;
}
.c8 .farnorth div.link .open:before,
.c8 .farnorth div.load .open:before,
.c8 .farnorth div.link .load:before,
.c8 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23F7B49A' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c8 .farnorth div.link .open:hover,
.c8 .farnorth div.load .open:hover,
.c8 .farnorth div.link .load:hover,
.c8 .farnorth div.load .load:hover,
.c8 .farnorth div.link .open:focus,
.c8 .farnorth div.load .open:focus,
.c8 .farnorth div.link .load:focus,
.c8 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c8 .main .pure div.link .open,
.c8 .main .pure div.load .open,
.c8 .main .pure div.link .load,
.c8 .main .pure div.load .load {
  background: #F7B49A;
  color: #fff;
  transition: opacity 250ms;
}
.c8 .main .pure div.link .open:before,
.c8 .main .pure div.load .open:before,
.c8 .main .pure div.link .load:before,
.c8 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c8 .main .pure div.link .open:hover,
.c8 .main .pure div.load .open:hover,
.c8 .main .pure div.link .load:hover,
.c8 .main .pure div.load .load:hover,
.c8 .main .pure div.link .open:focus,
.c8 .main .pure div.load .open:focus,
.c8 .main .pure div.link .load:focus,
.c8 .main .pure div.load .load:focus {
  background: #F7B49A;
  opacity: 0.8;
}
.c8 .side div.link .open,
.c8 .side div.load .open,
.c8 .side div.link .load,
.c8 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23F7B49A' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c9 .section--header {
  background-color: #E2C755;
  color: #fff;
}
.c9 .main h2,
.c9 .side h2,
.c9 .main h3,
.c9 .side h3 {
  color: #E2C755;
}
.c9 .unit.seam {
  background-color: rgba(226, 199, 85, 0.2);
}
.c9 .unit.flat {
  border: 1px solid rgba(226, 199, 85, 0.2);
}
.c9 .farnorth div.link .open,
.c9 .farnorth div.load .open,
.c9 .farnorth div.link .load,
.c9 .farnorth div.load .load {
  background: #fff;
  color: #E2C755;
  transition: opacity 250ms;
}
.c9 .farnorth div.link .open:before,
.c9 .farnorth div.load .open:before,
.c9 .farnorth div.link .load:before,
.c9 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23E2C755' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c9 .farnorth div.link .open:hover,
.c9 .farnorth div.load .open:hover,
.c9 .farnorth div.link .load:hover,
.c9 .farnorth div.load .load:hover,
.c9 .farnorth div.link .open:focus,
.c9 .farnorth div.load .open:focus,
.c9 .farnorth div.link .load:focus,
.c9 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c9 .main .pure div.link .open,
.c9 .main .pure div.load .open,
.c9 .main .pure div.link .load,
.c9 .main .pure div.load .load {
  background: #E2C755;
  color: #fff;
  transition: opacity 250ms;
}
.c9 .main .pure div.link .open:before,
.c9 .main .pure div.load .open:before,
.c9 .main .pure div.link .load:before,
.c9 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c9 .main .pure div.link .open:hover,
.c9 .main .pure div.load .open:hover,
.c9 .main .pure div.link .load:hover,
.c9 .main .pure div.load .load:hover,
.c9 .main .pure div.link .open:focus,
.c9 .main .pure div.load .open:focus,
.c9 .main .pure div.link .load:focus,
.c9 .main .pure div.load .load:focus {
  background: #E2C755;
  opacity: 0.8;
}
.c9 .side div.link .open,
.c9 .side div.load .open,
.c9 .side div.link .load,
.c9 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23E2C755' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c10 .section--header {
  background-color: #D0C94D;
  color: #fff;
}
.c10 .main h2,
.c10 .side h2,
.c10 .main h3,
.c10 .side h3 {
  color: #D0C94D;
}
.c10 .unit.seam {
  background-color: rgba(208, 201, 77, 0.2);
}
.c10 .unit.flat {
  border: 1px solid rgba(208, 201, 77, 0.2);
}
.c10 .farnorth div.link .open,
.c10 .farnorth div.load .open,
.c10 .farnorth div.link .load,
.c10 .farnorth div.load .load {
  background: #fff;
  color: #D0C94D;
  transition: opacity 250ms;
}
.c10 .farnorth div.link .open:before,
.c10 .farnorth div.load .open:before,
.c10 .farnorth div.link .load:before,
.c10 .farnorth div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23D0C94D' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c10 .farnorth div.link .open:hover,
.c10 .farnorth div.load .open:hover,
.c10 .farnorth div.link .load:hover,
.c10 .farnorth div.load .load:hover,
.c10 .farnorth div.link .open:focus,
.c10 .farnorth div.load .open:focus,
.c10 .farnorth div.link .load:focus,
.c10 .farnorth div.load .load:focus {
  background: #fff;
  opacity: 0.8;
}
.c10 .main .pure div.link .open,
.c10 .main .pure div.load .open,
.c10 .main .pure div.link .load,
.c10 .main .pure div.load .load {
  background: #D0C94D;
  color: #fff;
  transition: opacity 250ms;
}
.c10 .main .pure div.link .open:before,
.c10 .main .pure div.load .open:before,
.c10 .main .pure div.link .load:before,
.c10 .main .pure div.load .load:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.c10 .main .pure div.link .open:hover,
.c10 .main .pure div.load .open:hover,
.c10 .main .pure div.link .load:hover,
.c10 .main .pure div.load .load:hover,
.c10 .main .pure div.link .open:focus,
.c10 .main .pure div.load .open:focus,
.c10 .main .pure div.link .load:focus,
.c10 .main .pure div.load .load:focus {
  background: #D0C94D;
  opacity: 0.8;
}
.c10 .side div.link .open,
.c10 .side div.load .open,
.c10 .side div.link .load,
.c10 .side div.load .load {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23D0C94D' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
}
.theme-link {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(45, 60, 90, 0.2);
  background-image: url(/extras/images/bild.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto 100%;
  text-align: left;
}
.theme-link h3.theme-title {
  color: #2D3C5A;
}
.theme-link .fake-link {
  box-sizing: border-box;
  padding-left: 15px;
  background: url(/images/icon-caret-right.svg) no-repeat left -5px center;
  background-size: 15px 15px;
  color: #D02C7D;
  transition: background 250ms, color 250ms;
}
.theme-link .fake-link:hover,
.theme-link .fake-link:focus {
  background-image: url(/images/icon-caret-right-violet.svg);
  color: #962d87;
}
.theme-link.theme-link--sexuelle-gewalt {
  background-color: rgba(167, 213, 194, 0.2);
  background-image: url(/extras/images/illu_sexuelle_gewalt-2019-05-22.svg);
}
.theme-link.theme-link--stalking {
  background-color: rgba(162, 138, 157, 0.2);
  background-image: url(/extras/images/illu_stalking_cyberstalking-2019-05-22.svg);
}
.theme-link.theme-link--verkehrsunfall {
  background-color: rgba(84, 112, 140, 0.2);
  background-image: url(/extras/images/illu_verkehrsunfall-2019-05-22.svg);
}
.theme-link.theme-link--zwangsmassnahmen {
  background-color: rgba(247, 180, 154, 0.2);
  background-image: url(/extras/images/illu_zwangsmassnahme-2019-05-22.svg);
}
.theme-link.theme-link--koerperverletzung {
  background-color: rgba(172, 180, 184, 0.2);
  background-image: url(/extras/images/illu_koerperverletzung-2019-05-22.svg);
}
.theme-link.theme-link--toetungsdelikt {
  background-color: rgba(217, 204, 192, 0.2);
  background-image: url(/extras/images/illu_toetungsdelikt-2019-05-22.svg);
}
.theme-link.theme-link--drohung {
  background-color: rgba(240, 131, 114, 0.2);
  background-image: url(/extras/images/illu_drohung-2019-05-22.svg);
}
.theme-link.theme-link--haeusliche-gewalt {
  background-color: rgba(226, 199, 85, 0.2);
  background-image: url(/extras/images/illu_haeusliche_gewalt-2019-05-22.svg);
}
.theme-link.theme-link--zwangsheirat {
  background-color: rgba(208, 201, 77, 0.2);
  background-image: url(/extras/images/illu_zwangsheirat-2019-05-22.svg);
}
.cbdModule--stoerer {
  position: absolute;
  right: 0;
  bottom: -60px;
  z-index: 11;
}
#view .cbdModule--stoerer.is-empty {
  display: none;
}
@media (max-width: 1279px) {
  .cbdModule--stoerer {
    bottom: -40px;
  }
}
@media (max-width: 767px) {
  .cbdModule--stoerer {
    bottom: -20px;
  }
}
.stoerer__link {
  float: left;
  width: 430px;
  height: 430px;
  border-radius: 10000px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 40px 40px 100px;
  font-size: 60px;
  line-height: 1.1;
  background-size: 32px auto,
    100% 100%;
  background-position: center bottom 90px,
    50% 50%;
  background-repeat: no-repeat, no-repeat;
  background-image: url(/images/stoerer-chevron.svg), linear-gradient(-45deg, #e62e78 0%, #982886 100%);
  transition: all 0.4s;
}
.stoerer__link:hover {
  transform: rotate(-10deg);
}
@media (max-width: 1279px) {
  .stoerer__link {
    width: 240px;
    height: 240px;
    padding: 20px 20px 60px;
    font-size: 30px;
    background-size: 22px auto,
      100% 100%;
    background-position: center bottom 50px,
      50% 50%;
  }
}
@media (max-width: 767px) {
  .stoerer__link {
    width: 160px;
    height: 160px;
    padding: 10px 10px 40px;
    font-size: 22px;
    background-size: 18px auto,
      100% 100%;
    background-position: center bottom 30px,
      50% 50%;
  }
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100% - 80px);
  width: 400px;
  z-index: 2100;
  font-family: 'AsapMedium', helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #2D3C5A;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    left: 5vw;
    bottom: 5vw;
    max-width: 90vw;
    font-size: 15px;
    line-height: 1.46666667;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    padding: 16px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
}
.cb-cookie-warning__button {
  float: left;
  width: 100%;
  cursor: pointer;
}
#cb-cookie-warning__button--decline {
  display: none;
}
#cb-cookie-warning__button--accept {
  float: left;
  display: block;
  background-color: #D02C7D;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
#cb-cookie-warning__button--accept:hover,
#cb-cookie-warning__button--accept:focus {
  color: #fff;
  background-color: #d53785;
  text-decoration: none;
  cursor: pointer;
}
#cb-cookie-warning__button--accept:active {
  outline: none;
  background-color: #a62364;
}
.service_quitLink {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: calc(50% - 45px);
  width: 115px;
  height: 40px;
  background-color: #D02C7D;
  color: #fff;
  text-align: center;
  font-size: 13px;
  line-height: 40px;
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
  transition: all 0.4s;
  z-index: 2100;
}
.service_quitLink:hover,
.service_quitLink:focus {
  color: #fff;
  background-color: #962d87;
  text-decoration: underline;
}
.cb-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  overflow: hidden;
  overflow-y: auto;
}
#view .cb-popup.is-hidden,
#view.areaTwenty--empty .cb-popup {
  display: none !important;
}
#edit .cb-popup {
  position: relative;
  float: left;
  height: auto;
  z-index: 10;
  display: block !important;
}
.cb-popup__cover {
  float: left;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.cb-popup__overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
#view .cb-popup__overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.cb-popup__wrapper {
  float: left;
  z-index: 2;
  width: 800px;
  max-width: 90%;
  min-height: 100%;
  position: relative;
  background-color: #D02C7D;
  box-sizing: border-box;
  padding: 60px;
}
#edit .cb-popup__wrapper {
  margin: 100px 0;
}
@media (max-width: 1279px) {
  .cb-popup__wrapper {
    padding: 60px 15px 15px;
  }
}
@media (max-width: 767px) {
  .cb-popup__wrapper {
    max-width: 84%;
  }
}
.cb-popup__content {
  float: left;
  color: #fff;
}
.cb-popup__content a {
  color: #fff;
}
.cb-closePopup {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-popup-toggle-close.svg);
}
h1,
h2,
h3 {
  font-weight: normal;
}
.farwest h1,
.farwest h2,
.farwest h3,
.fareast h1,
.fareast h2,
.fareast h3 {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
h1 {
  color: #fff;
  font-size: 35px;
  font-size: 3.5rem;
  line-height: 1.37142857;
}
.cb-layout1 h1 {
  color: #D02C7D;
}
.cb-layout4 h1 {
  color: #2D3C5A;
}
h2 {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.04;
}
.cb-layout1 .main h2,
.seam h2,
.flat h2 {
  color: #D02C7D;
}
.base h2,
.farwest h2,
.fareast h2 {
  color: #2D3C5A;
}
h3 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.3;
}
.farwest h3,
.fareast h3,
h3 .fold {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.44444444;
}
.main h3,
.farwest h3,
.fareast h3,
.seam h3,
.flat h3 {
  color: #D02C7D;
}
.text-section--h2 + .text-section,
.text-section--h3 + .text-section {
  margin-top: 6px;
}
.pale {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.85714286;
}
strong {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
a {
  color: #D02C7D;
  text-decoration: none;
  transition: color 250ms;
}
a:hover,
a:focus {
  color: #962d87;
}
.area.main {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.cb-layout1 .main {
  text-align: center;
}
.cb-layout2 .container--news,
.cb-layout3 .container--news,
.cb-layout4 .container--news {
  padding-top: 45px;
}
.cb-layout2 .container--news .desk,
.cb-layout3 .container--news .desk,
.cb-layout4 .container--news .desk {
  border-top: 1px solid #CBCBCB;
}
.farwest .unit,
.fareast .unit {
  margin-top: 2px;
  margin-bottom: 2px;
}
.unit.seam,
.unit.flat {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px 37px;
}
.unit.seam .part,
.unit.flat .part {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.unit.seam.slim,
.unit.flat.slim {
  padding: 7px 20px;
}
.unit.seam div.link .open,
.unit.flat div.link .open,
.unit.seam div.load .load,
.unit.flat div.load .load {
  color: #2D3C5A;
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
  transition: color 250ms;
}
.unit.seam div.link .open:hover,
.unit.flat div.link .open:hover,
.unit.seam div.load .load:hover,
.unit.flat div.load .load:hover,
.unit.seam div.link .open:focus,
.unit.flat div.link .open:focus,
.unit.seam div.load .load:focus,
.unit.flat div.load .load:focus {
  color: #4f699e;
}
.unit.seam {
  background-color: rgba(45, 60, 90, 0.2);
}
.areaTwenty .unit.seam {
  padding: 10px 20px;
}
.unit.flat {
  border: 1px solid rgba(45, 60, 90, 0.2);
}
.main .unit.fold div.less,
.main .unit.fold div.more {
  float: left;
  width: 100%;
}
.main .unit.fold .ctrl {
  display: none;
}
.main .unit.fold .fold-toggle {
  box-sizing: border-box;
  display: block;
  padding-right: 30px;
  background: url(/images/icon-plus.svg) no-repeat right center;
  background-size: 30px 30px;
}
.main .unit.fold .fold-toggle.fold-toggle--open {
  background-image: url(/images/icon-minus.svg);
}
.unit.form .tick.tile .name {
  display: none;
}
.unit.form .tick {
  margin-top: -13px;
}
.unit.form .tick .ctrl > div {
  position: relative;
  margin-top: 10px;
}
.unit.form .tick .radio,
.unit.form .tick .checkbox {
  position: absolute;
  left: 0;
  top: 8px;
  width: 14px;
  height: 14px;
}
.unit.form .tick .sign {
  box-sizing: border-box;
  padding-left: 20px;
}
.unit.form input.text,
.unit.form input.sign,
.unit.form input.password,
.unit.form textarea,
.unit.form input.hint,
.unit.form textarea.hint,
.unit.form input.capt {
  border-radius: 6px;
  padding: 7px 10px;
}
.unit.form .foot {
  float: left;
  padding-bottom: 0;
}
.unit.form .foot input.submit,
.unit.form .foot button.cb-hybrid {
  display: inline-block;
  min-width: 121px;
  border-radius: 6px;
  padding: 6px 30px 6px 37px;
  background-color: #D02C7D;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: left 22px center;
  color: #fff;
  text-align: left;
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
.areaTwenty .unit {
  margin-top: 0;
  margin-bottom: 0;
}
body.inkognitoModeTrue .areaTwenty .unit.seam {
  display: none;
}
.text-section {
  float: left;
  width: 100%;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 16px;
  position: relative;
}
.list--bullet li:before {
  content: '–';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 16px;
}
div.load {
  background: none !important;
}
div.link .open,
div.load .open,
div.link .load,
div.load .load {
  box-sizing: border-box;
  padding-left: 15px;
  background: url(/images/icon-caret-right.svg) no-repeat left -5px center;
  background-size: 15px 15px;
  transition: background 250ms, color 250ms;
}
div.link .open:hover,
div.load .open:hover,
div.link .load:hover,
div.load .load:hover,
div.link .open:focus,
div.load .open:focus,
div.link .load:focus,
div.load .load:focus {
  background-image: url(/images/icon-caret-right-violet.svg);
}
.areaTwenty div.link .open,
.areaTwenty div.load .open,
.areaTwenty div.link .load,
.areaTwenty div.load .load {
  color: #fff !important;
  background-image: url(/images/icon-caret-right-white.svg);
}
div.link.phone .open {
  padding-left: 27px;
  background: url(/images/icon-phone.svg) no-repeat left center;
  background-size: 21px 21px;
}
div.link.mail .open {
  padding-left: 27px;
  background: url(/images/icon-envelope.svg) no-repeat left center;
  background-size: 21px 21px;
}
.farnorth div.link .open,
.main .pure div.link .open,
.farnorth div.load .open,
.main .pure div.load .open,
.farnorth div.link .load,
.main .pure div.link .load,
.farnorth div.load .load,
.main .pure div.load .load {
  display: inline-block;
  min-width: 121px;
  border-radius: 6px;
  padding: 6px 15px 6px 10px;
  background: #D02C7D;
  color: #fff;
  text-align: center;
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
  transition: background 250ms;
}
.farnorth div.link .open:before,
.main .pure div.link .open:before,
.farnorth div.load .open:before,
.main .pure div.load .open:before,
.farnorth div.link .load:before,
.main .pure div.link .load:before,
.farnorth div.load .load:before,
.main .pure div.load .load:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 34 34'%3e%3cpath fill='%23fff' d='M11.39 8.43a1.19 1.19 0 0 1 .83-1.19 1.24 1.24 0 0 1 1.43.26l8.56 8.57a1.28 1.28 0 0 1 0 1.86l-8.56 8.57a1.24 1.24 0 0 1-1.43.26 1.19 1.19 0 0 1-.83-1.19z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center top 1px;
}
.farnorth div.link .open:hover,
.main .pure div.link .open:hover,
.farnorth div.load .open:hover,
.main .pure div.load .open:hover,
.farnorth div.link .load:hover,
.main .pure div.link .load:hover,
.farnorth div.load .load:hover,
.main .pure div.load .load:hover,
.farnorth div.link .open:focus,
.main .pure div.link .open:focus,
.farnorth div.load .open:focus,
.main .pure div.load .open:focus,
.farnorth div.link .load:focus,
.main .pure div.link .load:focus,
.farnorth div.load .load:focus,
.main .pure div.load .load:focus {
  background-color: #962d87;
}
.cb-googlemapscontainer {
  height: 320px !important;
}
.cb-googlemapscontainer > div {
  height: 100% !important;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.625em;
}
fieldset .name,
label.sign {
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #2D3C5A;
  background-color: #fff;
  font-weight: normal;
  font-family: 'AsapMedium', helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.625em;
  border: 1px solid #2D3C5A;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 16px;
  font-size: 1.6pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'AsapMedium', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #6b84b5;
}
input.text[disabled],
textarea[disabled] {
  border-color: #35476b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #D02C7D;
  box-shadow: 0 0 3px rgba(208, 44, 125, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #dd5d9c;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #dd5d9c;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #D02C7D;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #d53785;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #a62364;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.625em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-top.less 2014-11-04 ***/
.navi {
  display: none;
}
.togglenavigation {
  position: relative;
  z-index: 211;
  float: left;
  display: block;
  width: 33px;
  height: 28px;
  margin-top: 3px;
  cursor: pointer;
  color: #D02C7D !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #ae2569 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 4px;
  width: 33px;
  margin-top: -2px;
  background: currentColor;
}
.togglenavigation > .tline-1,
.togglenavigation > .tline-4 {
  top: 0;
  margin-top: 0;
}
.togglenavigation > .tline-4 {
  top: auto;
  bottom: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  transform: rotate(45deg);
}
#mobile-navi {
  box-sizing: border-box;
  position: fixed;
  z-index: 210;
  left: 0;
  top: 0;
  width: 100%;
  padding-left: 0%;
  padding-right: 0%;
  height: 0;
  padding-top: 12px;
  background: #fff;
  overflow: auto;
  transition: all 0.3s ease-in;
}
body.cb-toggle-target-active #mobile-navi {
  height: 100vh;
  overflow: scroll;
  transition: all 0.218s ease-out;
}
#mobile-navi .navi.sub1 {
  float: left;
  display: block;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 30px;
}
#mobile-navi .navi.sub1 > .item {
  width: 100%;
  float: left;
  position: relative;
  padding: 20px 0px;
  border-bottom: 1px solid #CBCBCB;
}
#mobile-navi .navi.sub1 > .item.init {
  border-top: 1px solid #CBCBCB;
}
#mobile-navi .navi.sub1 > .item > .menu {
  display: block;
  color: #2D3C5A;
  padding: 2px 0;
  padding-right: 70px;
  font-size: 20px;
  line-height: 26px;
  font-family: 'AsapSemiBold', helvetica, sans-serif;
}
#mobile-navi .navi.sub1 > .item > .menu.path {
  color: #D02C7D;
}
#mobile-navi .navi.sub2 {
  float: left;
  display: block;
  width: 100%;
  max-height: 0;
  padding-top: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub2 .item {
  position: relative;
  width: 100%;
  float: left;
  padding: 10px 0;
}
#mobile-navi .navi.sub2 .item .menu {
  padding-left: 15px;
  background: url(/images/icon-caret-right.svg) no-repeat left -5px center;
  background-size: 15px 15px;
  color: #2D3C5A;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
}
#mobile-navi .navi.sub2 .item .menu.path {
  color: #D02C7D;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  padding-top: 15px;
  max-height: 1000px;
  transition: all 2s;
}
#mobile-navi .sub1 .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 70px;
  height: 70px;
  background: url(/images/icon-plus.svg) no-repeat right center;
  background-size: 30px 30px;
}
#mobile-navi .sub1 .cb-toggle:hover,
#mobile-navi .sub1 .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .sub1 .cb-toggle.cb-toggle-active {
  background-image: url(/images/icon-minus.svg);
  transition: all 0.218s;
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
.desk {
  max-width: 84%;
}
#home {
  width: 154px;
  height: 52px;
  margin-top: 13px;
  margin-bottom: 15px;
}
#head {
  margin-top: 27px;
}
.cb-layout1 #head {
  margin-top: 28px;
}
.nav.nav--mobile {
  display: block;
  margin-top: 23px;
}
.navi-services .meta {
  margin: 0 12px;
}
.navi-services .meta:first-child {
  margin-left: 0;
}
.navi-services .meta.service_spenden {
  background-image: url(/images/icon-heart.svg);
}
.navi-services .meta.service_phoneMobile {
  background-image: url(/images/icon-phone.svg);
}
.navi-services .meta.service_emailMobile {
  background-image: url(/images/icon-envelope.svg);
}
#naviServicesMobile {
  float: left;
}
#mobile-navi .navi-services {
  margin-top: 23px;
}
#mobile-navi .navi-services .meta {
  padding-top: 43px;
  background-position: center top;
  color: #707070;
}
#naviPhonesMobile {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  float: left;
  width: 100%;
  margin-top: 20px;
}
#naviPhonesMobile .meta {
  margin-top: 10px;
  padding-left: 20px;
  background-size: 15px 15px;
  background-position: left center;
  background-image: url(/images/icon-phone-grey.svg);
  background-repeat: no-repeat;
}
.section--header {
  margin-top: 92px;
}
.section--footer {
  text-align: center;
}
.section--footer h3 {
  font-weight: normal;
  font-family: 'AsapBold', helvetica, sans-serif;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1;
}
.section--footer .logo-container {
  margin: 30px auto 0;
}
.section--footer .links {
  margin-top: 40px;
}
.section--footer .footer-services {
  margin-top: 40px;
}
.section--footer .footer-services .services > span {
  display: none;
}
.section--footer #cmsbox {
  margin-top: 18px;
}
.theme-link {
  height: 112px;
  padding: 20px 50% 22px 27px;
}
.theme-link h3.theme-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
}
.theme-link .fake-link {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.38461538;
}
.cb-layout1 h1 {
  font-size: 45px;
  font-size: 4.5rem;
  line-height: 1.11111111;
}
h2 .cb-layout1 .main {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.33333333;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .farnorth .unit:first-child {
    margin-top: 0;
  }
  .cb-layout2 .farnorth .part.text,
  .cb-layout3 .farnorth .part.text {
    display: none;
  }
}
.area.farnorth {
  margin-bottom: 150px;
}
.cb-layout2 .area.farnorth,
.cb-layout3 .area.farnorth {
  margin-bottom: 120px;
}
.area.main {
  margin-top: 14px;
  margin-bottom: 14px;
}
.container--base {
  margin-top: 70px;
}
.base {
  margin-bottom: 10px;
}
.farwest,
.fareast {
  margin-top: 35px;
}
.fareast {
  margin-bottom: 35px;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-small.css.map */